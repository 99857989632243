<div class="page-wrapper">
  <div class="authentication-main">
    <div class="authentication-box">
      <div class="text-center"><img src="assets/images/logo.png"   /></div>
      <div class="card mt-4">
		<div class="card-header" style="padding:15px;background-color:#e5e5e5">                
			<h5>Sign In</h5> 
		</div>
        <div class="card-body">          		                                
          <form class="needs-validation" [formGroup]="loginForm" *ngIf="!newUser">
		  <div *ngIf="isShown" style="color:red;" >{{msg}}</div>
            <div class="form-group">
              <label class="col-form-label pt-0">Username</label>
              <select class="form-control select" formControlName="username" name="username" placeholder="" [ngModel]="username" (change)="onUser()">	
				<option value="">Select</option>                                                                   
				<option *ngFor="let d of customers" value={{d.customer_id}}>{{d.customer_id}}</option>                                                                   
				</select>	
              <div *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].errors?.['required']" class="text text-danger mt-1">Username is required</div>
              
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input class="form-control" type="password" formControlName="password" required="" [ngModel]="password"/>
              <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']" class="text text-danger mtz-1">Password is required</div>
            </div>
            <div class="checkbox p-0">
              <input id="checkbox1" type="checkbox" />
              <label for="checkbox1">Remember me</label>
            </div>
            <div class="form-group row mt-3 mb-0">
			<!--[disabled]="!loginForm.valid"-->
              <button class="btn btn-secondary btn-block"  (click)="login()" type="submit">
                <span>Login</span>
              </button>
            </div>
            <!--<div class="login-divider"></div>-->
            <!--<div class="social mt-3">
              <div class="form-group btn-showcase d-flex">
                 <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
                  <i class="fa fa-facebook"></i>
                </button>
                <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
                  <i class="fa fa-twitter"></i>
                </button>
                <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
                  <i class="fa fa-google"></i>
                </button> 
              </div>
            </div>-->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
