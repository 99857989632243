import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient ,HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import { formatDate } from "@angular/common";


type UserFields = "email" | "password";
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

	val:any;
	sub:any;
	customers:any;
	isShown: boolean = false ;	
	submitted: boolean = false;
	cmpisShown: boolean = false ;	
	cmpsubmitted: boolean = false;	
	msg:any;
	cmpmsg:any;
	dst_date_from:any;
	dst_date_to:any;
	headers = new HttpHeaders({ 'Content-Type': 'application/json','API_KEY':'20e20m06i16e14r31p','Version':'1.0' });    	 
	options = { headers: this.headers };	
	username:any;
	password:any;
	token:any;
	opt:any;
	
	public newUser = false;
	// public user: firebase.User;
	public loginForm: FormGroup;

	public errorMessage: any;

	constructor(private fb: FormBuilder, public router: Router,private http: HttpClient) {
	this.loginForm = this.fb.group({
	  username: ["", [Validators.required]],
	  password: ["", Validators.required],
	});
	}

  ngOnInit() 
  {
  
  
		this.username='A';
		this.password='123456';
	  
	  
		this.http.post('http://ec2-65-1-114-150.ap-south-1.compute.amazonaws.com:3000/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			//alert(this.token.access_token);
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };					
					
			
			this.http.get('http://ec2-65-1-114-150.ap-south-1.compute.amazonaws.com:3000/customers',o).subscribe((response) => 
			{ 
					//alert(JSON.stringify(response));
					this.customers=response;				
			});
					
			
						
		});	
  
  
  }

  login() {
  
	
	  
    this.submitted = true;
        // stop here if form is invalid
	if (this.loginForm.invalid) 
	{		
		return;
	}	
	
	localStorage.setItem('username', this.loginForm.value.username);	
	//event.preventDefault();
	this.router.navigate(['/dashboard/demo'])
	  
  }

  loginFacebook(){

  }
  loginTwitter(){

  }
  loginGoogle(){

  }
  
  onUser()
  {
	  this.loginForm.controls['password'].setValue('123456');	
  }

}
